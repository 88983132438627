<template>
    <div :class="{ 'row': inline }">
        <template v-if="inline">
            <div :class="[getClass(0)]" v-if="title">
                <label class="form-label" :title="title" :for="for">{{ title }}</label>
            </div>
            <div :class="[getClass(1)]">
                <slot></slot>
                <div v-if="helpText && inlineClasses.length == 2" class="form-text"></div>
            </div>
            <div v-if="helpText && inlineClasses && inlineClasses.length > 2" :class="[getClass(2)]">
                <span class="form-text">{{ helpText }}</span>
            </div>

        </template>
        <template v-else>
            <label class="form-label" :title="title" :for="for">{{ title }}</label>
            <slot></slot>
            <div v-if="helpText" class="form-text">{{ helpText }}</div>
        </template>

    </div>
</template>

<script setup lang="ts">
const props = defineProps<{
    title?: string
    for?: string
    helpText?: string,
    inline?: boolean,
    inlineClasses?: Array<string>
}>();

const getClass = (pIndex) => {
    if (props.inlineClasses[pIndex]) {
        return props.inlineClasses[pIndex];
    }

    return 'col-auto';
}
</script>
